<template>
  <div class='text-center' id='404'>
    <h3 class='mb-4'>404 error</h3>
    抱歉，此頁面不存在！<br />
    請返回上一頁，或點選上方連結觀看其他頁面。
  </div>
</template>

<script>
export default {
  name: '404',
  data() {
    return {
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
