const portfolioDataSet = {
  webs: [
    {
      type: 'Web',
      ifcase: '自行練習',
      img: 'guand_ver1.jpg',
      name: '個人網頁 Ver.1',
      date: '2016',
      description: '第一版個人網頁。',
      link: 'https://portfolio.jsy.tw/guand2016',
      keywords: ['Angular 1', 'jQuery', 'Bootstrap 3', 'RWD'],
      longDescription: '超快ㄉ',
    },
    {
      type: 'Web',
      ifcase: '自行練習',
      img: 'hsinchu.jpg',
      name: '在風城的故事',
      date: '2016',
      description: '獻給陪伴我長大的城市。',
      link: 'https://hsinchu.jsy.tw/',
      keywords: ['jQuery', 'Bootstrap 3', 'RWD'],
    },
    {
      type: 'Web',
      ifcase: '自行練習',
      img: 'fish-story.jpg',
      name: '梁靜茹 Fish-Story',
      date: '2016',
      description: '獻給我最喜愛的歌手。',
      link: 'https://fish.jsy.tw/',
      keywords: ['PHP', 'jQuery', 'Bootstrap 3', 'RWD'],
    },
    {
      type: 'Web',
      ifcase: '接案作品',
      img: 'nlplab.jpg',
      name: '元智大學自然語言處理實驗室',
      date: '2017',
      description: '大學實驗室的官網。',
      link: 'https://portfolio.jsy.tw/nlplab',
      keywords: ['jQuery', 'Bootstrap 3', 'RWD'],
    },
    {
      type: 'Web',
      ifcase: '自行練習',
      img: 'guand_ver2.png',
      name: '個人網頁 Ver.2',
      date: '2017',
      description: '現在個人網頁的前身。',
      link: 'https://portfolio.jsy.tw/guand2017',
      keywords: ['Angular 1', 'jQuery', 'Bootstrap 3', 'RWD'],
    },
    {
      type: 'Web',
      ifcase: '接案作品（未公開）',
      img: 'stockAI.jpg',
      name: 'AI台股決策&輿情分析系統法人版',
      date: '2018',
      description: '搭載輿情分析的股票決策系統。',
      link: null,
      keywords: ['PHP', 'Vue 2', 'jQuery', 'Bootstrap 4', 'highcharts'],
    },
    {
      type: 'Web',
      ifcase: '接案作品（未公開）',
      img: 'yzusocial.png',
      name: '新元智大學社群媒體分析平台',
      date: '2019',
      description: '社群媒體輿情分析 Dashboard。',
      link: null,
      keywords: ['Vue 2', 'Bootstrap 4', 'D3.js', 'highcharts', 'RWD'],
    },
    {
      type: 'Web',
      ifcase: '公司活動（已去識別）',
      img: 'mentoring-program.png',
      name: '饅頭記 Mentoring-Program',
      date: '2019',
      description: 'Mentor 經驗傳承活動官網。',
      link: 'https://portfolio.jsy.tw/mentoring-program/',
      keywords: ['Vue 2', 'Bootstrap 4', 'RWD'],
    },
    {
      type: 'Web',
      ifcase: '公司活動（已去識別）',
      img: 'talent-show-2020.png',
      name: 'Talent Show 2020',
      date: '2020',
      description: '新進員工的分組競賽活動官網。',
      link: 'https://portfolio.jsy.tw/talent-show/',
      keywords: ['Vue 2', 'Bootstrap 4', 'RWD'],
    },
    {
      type: 'Web',
      ifcase: '自行練習',
      img: 'traintime.png',
      name: '台鐵時刻查詢',
      date: '2020, 2023 (後端)',
      description: '查詢火車時刻，簡單又快速。',
      link: 'https://portfolio.jsy.tw/traintime',
      keywords: ['Vue 2', 'Express.js', 'Bootstrap-Vue', 'RWD'],
    },
    {
      type: 'Web',
      ifcase: '自行練習',
      img: 'hs-traintime.png',
      name: '高鐵時刻查詢',
      date: '2021, 2024 (後端)',
      description: '查詢高鐵時刻，簡單又快速。',
      link: 'https://portfolio.jsy.tw/hs-traintime',
      keywords: ['Vue 3', 'Express.js', 'Bootstrap 5', 'RWD'],
    },
    {
      type: 'Web',
      ifcase: '公司活動（已去識別）',
      img: 'university-2023-cover.png',
      name: 'Consulting University 2023',
      date: '2022',
      description: '顧問公司的員工培訓活動官網。',
      link: 'https://portfolio.jsy.tw/university/',
      keywords: ['React 18', 'Bootstrap 5', 'RWD'],
    },
    {
      type: 'Web',
      ifcase: '自行練習',
      img: 'new-train-time.png',
      name: '新-台鐵時刻查詢',
      date: '2024',
      description: '專為通勤族設計的時刻表。',
      link: 'https://traintime.jsy.tw/',
      keywords: ['React 18', 'Next.js', 'TailwindCSS', 'RWD'],
    },
  ],
  chatbots: [
    {
      type: 'Chatbot',
      ifcase: '碩論研究（服務已停止）',
      img: 'chatbot-healthcare.jpeg ',
      name: 'LINE 冠心病智慧衛教機器人 beta',
      date: '2018',
      description: '快速取得衛教知識 & 檢測罹病風險。',
      link: 'http://qr-official.line.me/L/brmbM1vOPW.png',
    },
  ],
  blogThemes: [
    {
      type: 'Blog-Theme',
      rel: 'gallery',
      ifcase: 'Yahoo!Blog',
      img: 'y_blog5.jpg',
      name: '千年傳統全新感受',
      date: '2008',
      description: '',
      link: 'https://s3.amazonaws.com/jhihsiyingweb/Guand.me/works/y_blog5.jpg',
    },
    {
      type: 'Blog-Theme',
      rel: 'gallery',
      ifcase: 'Yahoo!Blog',
      img: 'y_blog6.jpg',
      name: '敗犬女王',
      date: '2009',
      description: '',
      link: 'https://s3.amazonaws.com/jhihsiyingweb/Guand.me/works/y_blog6.jpg',
    },
    {
      type: 'Blog-Theme',
      rel: 'gallery',
      ifcase: 'Yahoo!Blog',
      img: 'y_blog7.jpg',
      name: '我喜愛的歌手們(Yahoo!Blog)',
      date: '2009',
      description: '',
      link: 'https://s3.amazonaws.com/jhihsiyingweb/Guand.me/works/y_blog7.jpg',
    },
    {
      type: 'Blog-Theme',
      rel: 'gallery',
      ifcase: 'Yahoo!Blog',
      img: 'y_blog8.jpg',
      name: '那年夏天',
      date: '2009',
      description: '',
      link: 'https://s3.amazonaws.com/jhihsiyingweb/Guand.me/works/y_blog8.jpg',
    },
    {
      type: 'Blog-Theme',
      rel: 'gallery',
      ifcase: 'Yahoo!Blog',
      img: 'y_blog9.jpg',
      name: '分離',
      date: '2009',
      description: '',
      link: 'https://s3.amazonaws.com/jhihsiyingweb/Guand.me/works/y_blog9.jpg',
    },
    {
      type: 'Blog-Theme',
      rel: 'gallery',
      ifcase: 'Yahoo!Blog',
      img: 'y_blog10.jpg',
      name: '那一年的幸福時光',
      date: '2009',
      description: '',
      link: 'https://s3.amazonaws.com/jhihsiyingweb/Guand.me/works/y_blog10.jpg',
    },
    {
      type: 'Blog-Theme',
      rel: 'gallery',
      ifcase: 'Yahoo!Blog',
      img: 'y_blog11.jpg',
      name: '藍天',
      date: '2009',
      description: '',
      link: 'https://s3.amazonaws.com/jhihsiyingweb/Guand.me/works/y_blog11.jpg',
    },
    {
      type: 'Blog-Theme',
      rel: 'gallery',
      ifcase: 'Yahoo!Blog',
      img: 'y_blog12.jpg',
      name: '我的聖誕我生日',
      date: '2009',
      description: '',
      link: 'https://s3.amazonaws.com/jhihsiyingweb/Guand.me/works/y_blog12.jpg',
    },
    {
      type: 'Blog-Theme',
      rel: 'gallery',
      ifcase: 'Yahoo!Blog',
      img: 'y_blog13.jpg',
      name: '海派甜心',
      date: '2010',
      description: '',
      link: 'https://s3.amazonaws.com/jhihsiyingweb/Guand.me/works/y_blog13.jpg',
    },
    {
      type: 'Blog-Theme',
      rel: 'gallery',
      ifcase: 'Yahoo!Blog',
      img: 'y_blog14.jpg',
      name: '痞子英雄(Yahoo!Blog)',
      date: '2010',
      description: '',
      link: 'https://s3.amazonaws.com/jhihsiyingweb/Guand.me/works/y_blog14.jpg',
    },
    {
      type: 'Blog-Theme',
      rel: 'gallery',
      ifcase: 'Yahoo!Blog',
      img: 'y_blog15.jpg',
      name: '蔚藍盛夏',
      date: '2010',
      description: '',
      link: 'https://s3.amazonaws.com/jhihsiyingweb/Guand.me/works/y_blog15.jpg',
    },
    {
      type: 'Blog-Theme',
      rel: 'gallery',
      ifcase: 'Yahoo!Blog',
      img: 'y_blog16.jpg',
      name: '近在咫尺',
      date: '2010',
      description: '',
      link: 'https://s3.amazonaws.com/jhihsiyingweb/Guand.me/works/y_blog16.jpg',
    },
    {
      type: 'Blog-Theme',
      rel: 'gallery',
      ifcase: 'Yahoo!Blog',
      img: 'y_blog17.jpg',
      name: '澶恬',
      date: '2010',
      description: '',
      link: 'https://s3.amazonaws.com/jhihsiyingweb/Guand.me/works/y_blog17.jpg',
    },
    {
      type: 'Blog-Theme',
      rel: 'gallery',
      ifcase: 'Yahoo!Blog',
      img: 'y_blog18.jpg',
      name: '魔導少年(Yahoo!Blog)',
      date: '2010',
      description: '',
      link: 'https://s3.amazonaws.com/jhihsiyingweb/Guand.me/works/y_blog18.jpg',
    },
    {
      type: 'Blog-Theme',
      rel: 'gallery',
      ifcase: 'Pixnet',
      img: 'p_blog0.jpg',
      name: '零-重新開始',
      date: '2011',
      description: '',
      link: 'https://s3.amazonaws.com/jhihsiyingweb/Guand.me/works/p_blog0.jpg',
    },
    {
      type: 'Blog-Theme',
      rel: 'gallery',
      ifcase: 'Pixnet',
      img: 'p_blog1.jpg',
      name: '痞子英雄(痞客邦)',
      date: '2011',
      description: '',
      link: 'https://s3.amazonaws.com/jhihsiyingweb/Guand.me/works/p_blog1.jpg',
    },
    {
      type: 'Blog-Theme',
      rel: 'gallery',
      ifcase: 'Pixnet',
      img: 'p_blog2.jpg',
      name: '魔導少年(痞客邦)',
      date: '2011',
      description: '',
      link: 'https://s3.amazonaws.com/jhihsiyingweb/Guand.me/works/p_blog2.jpg',
    },
    {
      type: 'Blog-Theme',
      rel: 'gallery',
      ifcase: 'Pixnet',
      img: 'p_blog3.jpg',
      name: '醉後決定愛上你',
      date: '2011',
      description: '',
      link: 'https://s3.amazonaws.com/jhihsiyingweb/Guand.me/works/p_blog3.jpg',
    },
    {
      type: 'Blog-Theme',
      rel: 'gallery',
      ifcase: 'Pixnet',
      img: 'p_blog4.jpg',
      name: '美樂加油',
      date: '2011',
      description: '',
      link: 'https://s3.amazonaws.com/jhihsiyingweb/Guand.me/works/p_blog4.jpg',
    },
    {
      type: 'Blog-Theme',
      rel: 'gallery',
      ifcase: 'Pixnet',
      img: 'p_blog5.jpg',
      name: '小資女孩向前衝',
      date: '2011',
      description: '',
      link: 'https://s3.amazonaws.com/jhihsiyingweb/Guand.me/works/p_blog5.jpg',
    },
    {
      type: 'Blog-Theme',
      rel: 'gallery',
      ifcase: 'Pixnet',
      img: 'p_blog6.jpg',
      name: '2012是芥茉日',
      date: '2011',
      description: '',
      link: 'https://s3.amazonaws.com/jhihsiyingweb/Guand.me/works/p_blog6.jpg',
    },
    {
      type: 'Blog-Theme',
      rel: 'gallery',
      ifcase: 'Pixnet',
      img: 'p_blog7.jpg',
      name: '我喜愛的歌手們(痞客邦)',
      date: '2012',
      description: '',
      link: 'https://s3.amazonaws.com/jhihsiyingweb/Guand.me/works/p_blog7.jpg',
    },
    {
      type: 'Blog-Theme',
      rel: 'gallery',
      ifcase: 'Pixnet',
      img: 'p_blog8.jpg',
      name: 'Best Friend',
      date: '2012',
      description: '',
      link: 'https://s3.amazonaws.com/jhihsiyingweb/Guand.me/works/p_blog8.jpg',
    },
  ],
  posters: [
    {
      type: 'Poster',
      rel: 'gallery',
      img: 'case1.jpg',
      ifcase: '接案作品',
      name: '樂高機器人營',
      for: '元智大學電機系',
      date: '2013',
      link: 'https://s3.amazonaws.com/jhihsiyingweb/Guand.me/works/case1.jpg',
    },
    // {
    //   type: 'Poster',
    //   rel: 'gallery',
    //   ifcase: '學校社團',
    //   img: 'pi1.jpg',
    //   name: '金頭腦',
    //   date: '2013',
    //   link: 'https://s3.amazonaws.com/jhihsiyingweb/Guand.me/works/pi1.jpg',
    // },
    // {
    //   type: 'Poster',
    //   rel: 'gallery',
    //   img: 'pi2.jpg',
    //   ifcase: '學校社團',
    //   name: '紫錐花反毒宣傳活動',
    //   date: '2014',
    //   link: 'https://s3.amazonaws.com/jhihsiyingweb/Guand.me/works/pi2.jpg',
    // },
    {
      type: 'Poster',
      rel: 'gallery',
      img: 'case2.jpg',
      ifcase: '接案作品',
      name: '藍鵲資訊志工',
      for: '龍華科大社團',
      date: '2014',
      link: 'https://s3.amazonaws.com/jhihsiyingweb/Guand.me/works/case2.jpg',
    },
    {
      type: 'Poster',
      rel: 'gallery',
      img: 'pi3.jpg',
      ifcase: '學校社團',
      name: '全元逃走中',
      date: '2014',
      link: 'https://s3.amazonaws.com/jhihsiyingweb/Guand.me/works/pi3.jpg',
    },
    {
      type: 'Poster',
      rel: 'gallery',
      img: 'pi4.jpg',
      ifcase: '學校社團',
      name: '健康性教育宣導',
      date: '2014',
      link: 'https://s3.amazonaws.com/jhihsiyingweb/Guand.me/works/pi4.jpg',
    },
    {
      type: 'Poster',
      rel: 'gallery',
      img: 'ai8.jpg',
      ifcase: '學校社團',
      name: '藥物濫用防制講座',
      date: '2014',
      link: 'https://s3.amazonaws.com/jhihsiyingweb/Guand.me/works/ai8.jpg',
    },
    // {
    //   type: 'Poster',
    //   rel: 'gallery',
    //   img: 'pi5.jpg',
    //   ifcase: '學校社團',
    //   name: '四大義工評鑑封面',
    //   date: '2014',
    //   link: 'https://s3.amazonaws.com/jhihsiyingweb/Guand.me/works/pi5.jpg',
    // },
    // {
    //   type: 'Poster',
    //   rel: 'gallery',
    //   img: 'pi6.jpg',
    //   ifcase: '學校社團',
    //   name: '社團博覽會招生系列1',
    //   date: '2014',
    //   link: 'https://s3.amazonaws.com/jhihsiyingweb/Guand.me/works/pi6.jpg',
    // },
    // {
    //   type: 'Poster',
    //   rel: 'gallery',
    //   img: 'ai1.jpg',
    //   ifcase: '學校社團',
    //   name: '社團博覽會招生系列2',
    //   date: '2014',
    //   link: 'https://s3.amazonaws.com/jhihsiyingweb/Guand.me/works/ai1.jpg',
    // },
    // {
    //   type: 'Poster',
    //   rel: 'gallery',
    //   img: 'ai2.jpg',
    //   ifcase: '學校社團',
    //   name: '社團博覽會招生系列3',
    //   date: '2014',
    //   link: 'https://s3.amazonaws.com/jhihsiyingweb/Guand.me/works/ai2.jpg',
    // },
    // {
    //   type: 'Poster',
    //   rel: 'gallery',
    //   img: 'ai3.jpg',
    //   ifcase: '學校社團',
    //   name: '社團博覽會招生系列4',
    //   date: '2014',
    //   link: 'https://s3.amazonaws.com/jhihsiyingweb/Guand.me/works/ai3.jpg',
    // },
    // {
    //   type: 'Poster',
    //   rel: 'gallery',
    //   img: 'ai4.jpg',
    //   ifcase: '學校社團',
    //   name: '新生說明會',
    //   date: '2014',
    //   link: 'https://s3.amazonaws.com/jhihsiyingweb/Guand.me/works/ai4.jpg',
    // },
    {
      type: 'Poster',
      rel: 'gallery',
      img: 'ai5.jpg',
      ifcase: '學校社團',
      name: '期初社大',
      date: '2014',
      link: 'https://s3.amazonaws.com/jhihsiyingweb/Guand.me/works/ai5.jpg',
    },
    {
      type: 'Poster',
      rel: 'gallery',
      img: 'ai13.jpg',
      ifcase: '學校社團',
      name: '六大義工開訓',
      date: '2014',
      link: 'https://s3.amazonaws.com/jhihsiyingweb/Guand.me/works/ai13.jpg',
    },
    {
      type: 'Poster',
      rel: 'gallery',
      img: 'ai6.jpg',
      ifcase: '學校社團',
      name: '健康週 - 心幸福紫錐花',
      date: '2014',
      link: 'https://s3.amazonaws.com/jhihsiyingweb/Guand.me/works/ai6.jpg',
    },
    {
      type: 'Poster',
      rel: 'gallery',
      img: 'ai7.jpg',
      ifcase: '學校社團',
      name: '金頭腦',
      date: '2014',
      link: 'https://s3.amazonaws.com/jhihsiyingweb/Guand.me/works/ai7.jpg',
    },
    {
      type: 'Poster',
      rel: 'gallery',
      img: 'ai9.jpg',
      ifcase: '學校社團',
      name: '藥物濫用防制講座',
      date: '2014',
      link: 'https://s3.amazonaws.com/jhihsiyingweb/Guand.me/works/ai9.jpg',
    },
    {
      type: 'Poster',
      rel: 'gallery',
      img: 'ai10.jpg',
      ifcase: '學校社團',
      name: '金頭腦',
      date: '2015',
      link: 'https://s3.amazonaws.com/jhihsiyingweb/Guand.me/works/ai10.jpg',
    },
    {
      type: 'Poster',
      rel: 'gallery',
      img: 'ai11.jpg',
      ifcase: '學校社團',
      name: '六大義工評鑑宣傳',
      date: '2015',
      link: 'https://s3.amazonaws.com/jhihsiyingweb/Guand.me/works/ai11.jpg',
    },
    {
      type: 'Poster',
      rel: 'gallery',
      img: 'ai12.jpg',
      ifcase: '學校社團',
      name: '六大義工評鑑封面',
      date: '2015',
      link: 'https://s3.amazonaws.com/jhihsiyingweb/Guand.me/works/ai12.jpg',
    },
    {
      type: 'Poster',
      rel: 'gallery',
      img: 'ai14.jpg',
      ifcase: '學校社團',
      name: '橘衛兵幹訓中心',
      date: '2015',
      link: 'https://s3.amazonaws.com/jhihsiyingweb/Guand.me/works/ai14.jpg',
    },
    {
      type: 'Poster',
      rel: 'gallery',
      img: 'case3.jpg',
      ifcase: '接案作品',
      name: '5Q賞金獵人',
      for: '元智大學生輔組',
      date: '2016',
      link: 'https://s3.amazonaws.com/jhihsiyingweb/Guand.me/works/case3.jpg',
    },
  ],
  videos: [
    {
      type: 'Video',
      img: 'ms20.jpg',
      ifcase: '自行練習',
      name: 'No.20 我就是我',
      date: '2011',
      description: '- 楓之谷僧侶三轉紀念影片 -',
      link: 'https://jsy.tw/blog/483/',
    },
    {
      type: 'Video',
      img: 'ms21.jpg',
      ifcase: '自行練習',
      name: 'No.21 下一頁的我',
      date: '2014',
      description: '- 楓之谷祭司四轉紀念影片 -',
      link: 'https://jsy.tw/blog/571',
    },
    {
      type: 'Video',
      img: 'yzuls2014.jpg',
      ifcase: '學校社團',
      name: '生活尖兵迎新宣傳影片（預告版）',
      date: '2014',
      description: '- 社團博覽會與迎新茶會宣傳 -',
      link: 'https://www.youtube.com/watch?v=dAFfxFxv9f0',
    },
    {
      type: 'Video',
      img: 'yzuls2014OP.jpg',
      ifcase: '學校社團',
      name: '生活尖兵 40 秒團務報告影片',
      date: '2014',
      description: '- 義工開訓典禮影片 -',
      link: 'https://www.youtube.com/watch?v=vGbEe-__DzY',
    },
    {
      type: 'Video',
      img: 'fintech2017.jpg',
      ifcase: '競賽作品',
      name: '金融社群媒體分析平台 Demo',
      date: '2017',
      description: '- 2017 上海商銀 Fintech 競賽 -',
      link: 'https://youtu.be/GQQ_qe0JUk0',
    },
    {
      type: 'Video',
      img: 'yzuls2018.jpg',
      ifcase: '學校社團',
      name: '生活尖兵 16 to 17 畢業紀念影片',
      date: '2018',
      description: '- 歡慶第 17 屆尖兵畢業 -',
      link: 'https://www.youtube.com/watch?v=ZWnZaRXU65E',
    },
  ],
};

export default portfolioDataSet;
